const m = require('mithril')
const yaml = require('js-yaml')
const control = require('../lib/control')
const Colored = require('./colored')

const EXAMPLES = [
  {
    id: 'version',
    query: 'ds.version'
  },
  {
    id: 'get(user).name',
    query: 'ds.get("user", { query: { email: "jakub@dayswaps.com" }}).then(u => u.name)'
  },
  {
    id: 'hash()',
    query: 'ds.hash("Dayswaps")'
  },
  {
    id: 'sign(session)',
    query: 'ds.sign("session", "Dayswaps")'
  },
  {
    id: 'verify(session)',
    query: 'ds.verify("session", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2cUJEVW1kRDZLOWkiLCJpc3MiOiJkczpzZXNzaW9uIiwiaWF0IjoxNTY1MTQ3ODQ4LCJleHAiOjE1Njc3Mzk4NDh9.DmbOe6lBS38VbCvRe8mexkcfNETUAq2NPggSX1tqCJs")'
  },
  {
    id: 'periodInLocalTime()',
    query: 'ds.periodInLocalTime({ start: "2019-08-22T00:00Z", end: "2019-08-22T08:00Z" }, "Pacific/Fiji")'
  },
  {
    id: 'dateFromDay()',
    query: 'ds.dateFromDay("2019-08-22", "Europe/Prague")'
  },
  {
    id: 'makePeriod(week)',
    query: 'ds.makePeriod("2019-08-22T00:00Z", "week", "Europe/Prague")'
  },
  {
    id: 't(key)',
    query: 'ds.t("internal.test1", { lng: "cs" })'
  },
  {
    id: 'delay()',
    query: 'ds.delay(500)'
  },
  {
    id: 'fake(companyName)',
    query: 'ds.fake("company.companyName", "cs")'
  },
  {
    id: 'diff()',
    query: 'ds.diff({ name: "Original" }, { name: "Renamed" })'
  },
  {
    id: 'shortUrl()',
    query: 'ds.shortUrl("https://dayswaps.com")'
  },
  {
    id: 'get(shift).warnings()',
    query: 'ds.get("shift", "5d5d567565497d6deb58d6e6").then(s => s.warnings())'
  },
  {
    id: 'publish()',
    query: 'ds.publish("testSubject", { example: true })'
  }
]

let query = `(async () => {
    
}).call(this)`
let pageStore = {}
let example = '---'
let loading = false

function queryRun () {
  pageStore = {}
  if (!query) {
    return null
  }
  loading = true
  const start = new Date()
  control.eval({ query }).then(res => {
    if (res.errors) {
      pageStore.errors = res.errors
    }
    pageStore.eval = res.data.eval
    pageStore.duration = new Date() - start
    loading = false
    m.redraw()
  })
}

function selectExample (type) {
  return function (e) {
    if (e.target) {
      type = e.target.value
    }
    if (type === '---') {
      return queryUpdate('')
    }
    const ex = EXAMPLES.find(e => e.id === type)
    if (!ex) {
      throw new Error('Example not found')
    }
    example = '---'
    queryUpdate(ex.query, true)
  }
}

function queryUpdate (e, run = false) {
  if (e && e.target) {
    query = e.target.value.trim()
  } else {
    query = e
  }
  m.route.set(`/engine/eval/${encodeURIComponent(query)}`)
  if (run) {
    queryRun()
  }
  m.redraw()
}

module.exports = {
  oninit (vnode) {
    if (vnode.attrs.query) {
      queryUpdate(vnode.attrs.query)
    }
  },
  onupdate (vnode) {
    /* if (!vnode.attrs.query && query) {
      query = ''
      pageStore = {}
      m.redraw()
    } */
  },
  onremove () {
    query = ''
    pageStore = {}
  },
  view (vnode) {
    const examples = [{ id: '---' }, ...EXAMPLES]
    return m('div', [
      m('.title.is-3', 'Engine'),
      m('.title.is-4', 'Eval'),
      m('.notification.is-warning', 'DANGEROUS!! Using eval can break core functionality.'),
      m('nav.level', [
        m('.level-left', [
          m('.level-item', 'Examples: '),
          m('.level-item', m('.select', m('select', { onchange: selectExample(), value: example }, examples.map(e => {
            return m('option', { value: e.id }, e.id)
          }))))
        ])
      ]),
      m('.content', [
        m('textarea.textarea', { onchange: queryUpdate, value: query }),
        m('button.button', { onclick: queryRun }, 'Run')
      ]),
      loading ? m('div', 'Running ...') : '',
      m('.content', [
        pageStore.eval !== undefined
          ? m('div', { style: 'margin-bottom: 2em;' }, [
            m('.title.is-5', ['Result', m('small', ` (${pageStore.duration}ms)`)]),
            m(Colored, { text: yaml.safeDump(pageStore.eval) })
          ])
          : '',
        pageStore.errors
          ? m('div', [
            m('.title.is-5', 'Result (errors)'),
            m('pre.notification.is-danger', yaml.safeDump(pageStore.errors))
          ])
          : ''
      ])
    ])
  }
}
